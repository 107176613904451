.selector-for-some-widget {
    box-sizing: content-box;
}

body{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
}
h1,h2,h3,h4,h5,h6{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.mt-100{
    margin-top: 100px;
}
.mb-100{
    margin-bottom: 100px;
}
.my-70{
    margin-top: 70px;
    margin-bottom: 70px;
}
.header__orange{
    background-color: $primary-orange;
    color: #fff;
    border-bottom: 1px solid $sky-blue;
}
button:focus{
    outline: 0px auto -webkit-focus-ring-color;
}
.btn-orange_outline{
    background-color: transparent;
    border-radius: 24px;
    padding: 6px 20px;
    border: 2px solid $primary-orange;
    color: $primary-orange;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    width: auto;
}
a.btn-orange_outline{
    background-color: #fff!important;
    border-radius: 24px!important;
    padding: 6px 20px!important;
    border: 2px solid $primary-orange!important;
    color: $primary-orange!important;
    text-transform: uppercase!important;
    font-family: 'Montserrat', sans-serif!important;
    font-weight: 700!important;
    font-size: 12px!important;
    width: auto!important;
    -webkit-appearance: none;
}
a.btn-orange_outline:hover{
    background-color: $primary-orange!important;
    color: #fff!important;
}
.btn-orange_outline:hover{
    background-color: $primary-orange!important;
    color: #fff!important;
}
.btn-orange{
    background-color: #fff;
    color: #02009d;
    border-radius: 24px;
    padding: 6px 20px;
    border: 2px solid $primary-orange;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    width: fit-content;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500
}

.btn-orange:disabled,
.btn-orange[disabled]{
    opacity: 0.5;
    cursor: not-allowed;
}

.btn-orange:hover{
    background-color: #52ece5;
    color: #fff;
}
.btn-white-outline{
    background-color: transparent;
    color: #fff;
    border-radius: 24px;
    padding: 6px 20px;
    border: 2px solid #fff;
    text-transform: uppercase;
    font-size: 14px;
    width: fit-content;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    cursor: pointer;
}
.btn-white-outline:hover{
    background-color: #ffffff29;
}
.btn-grey-outline{
    background-color: transparent;
    color: $dark-grey;
    border-radius: 24px;
    padding: 6px 20px;
    border: 2px solid $dark-grey;
    text-transform: uppercase;
    font-size: 14px;
    width: fit-content;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500
}
.btn-grey-outline:hover{
    opacity: 0.7;
}
.btn-blue-outline{
    background-color: transparent;
    color: $primary-blue;
    border-radius: 24px;
    padding: 6px 20px;
    border: 2px solid $primary-blue;
    text-transform: uppercase;
    font-size: 14px;
    width: fit-content;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500
}
.btn-blue-outline:hover{
    opacity: 0.8;
}
.btn-big{
    padding: 10px 30px;
}

.lines_after-titles{
    width: 40px; 
    color: $primary-orange;
    background-color: $primary-orange;
    height:3px;
    border:none;
}

.section__ppal{
    margin-top: 100px;
}

// @media (min-width: 768px){
//     .vph{
//         height: 100vh;
//         padding-top: 102px
//     }
// }
// @media (min-width: 1024px){
//     .vph{
//         height: 100vh;
//         padding-top: 140px
//     }
// }

@media (max-width: 768px){
    .btn-white-outline{
        display: block;
    }
    ul{
        padding-inline-start: 25px;
    }
}
@media (min-width: 1024px){
    .section__ppal{
        margin-top: 140px;
    }
}