a,
a:hover,
a:focus {
    color: inherit;
    text-decoration: none;
    transition: all 0.3s;
}
h4 {
    font-size: 1.2rem;
}
h6 {
    font-size: 0.9rem;
}
.navbar {
    padding: 15px 10px;
    background: #fff;
    border: none;
    border-radius: 0;
    margin-bottom: 40px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.navbar-btn {
    box-shadow: none;
    outline: none !important;
    border: none;
}

.line {
    width: 100%;
    height: 1px;
    border-bottom: 1px dashed #ddd;
    margin: 40px 0;
}

i,
span {
    display: inline-block;
}

/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
    display: flex;
    align-items: stretch;
}

#sidebar {
    // min-width: 250px;
    // max-width: 250px;
    min-width: 80px;
    max-width: 80px;
    background: #fff;
    color: $text-grey;
    transition: all 0.3s;
    -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    text-align: center;

    .collapsed-names{
        display: none;
    }
}

#sidebar.active {
    // min-width: 80px;
    // max-width: 80px;
    min-width: 250px;
    max-width: 250px;
    text-align: left;

    .collapsed-names{
        display: inline-block;
    }
}

.header__menu-dashboard{
    color: $primary-blue;
    display: none;
}

#sidebar.active .sidebar-header .header__menu-dashboard,
#sidebar.active .CTAs {
    display: inline-block;
}

#sidebar.active .sidebar-header strong {
    display: block;
}

#sidebar ul li a {
    text-align: center;
}

#sidebar.active ul li a {
    padding: 20px 10px;
    text-align: left;
    font-size: 0.85em;
    border-left: 5px solid transparent;

    &:hover {
        background: #F9FAFC;
        border-left: 5px solid #F15A29;
    }

    &.active {
        background: #F9FAFC;
        border-left: 5px solid #F15A29;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
    }
}

#sidebar.active ul li a i {
    margin-right: 0;
    display: block;
    font-size: 1.8em;
    margin-bottom: 5px;
}

#sidebar.active ul ul a {
    padding: 10px !important;
}

#sidebar .sidebar-header {
    display: flex;
    align-items: flex-start;
    padding: 20px;
    background: #fff;
    border-bottom: 1px solid #80808029;
}

#sidebar .sidebar-header strong {
    display: none;
    font-size: 1.8em;
}

#sidebar ul.components {
    padding: 20px 0;
}

#sidebar ul li a {
    padding: 15px 10px;
    font-size: 1.1em;
    display: block;
}

#sidebar ul li a:hover {
    background: #F9FAFC;
    border-left: 5px solid $primary-orange;
}

#sidebar ul li a i {
    margin-right: 10px;
}

#sidebar ul.menu-dashboard__ul li.active>a{
    background: #F9FAFC;
    border-left: 5px solid $primary-orange;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}

a[data-toggle="collapse"] {
    position: relative;
}


ul.CTAs {
    padding: 20px;
}

ul.CTAs a {
    text-align: center;
    font-size: 0.9em !important;
    display: block;
    border-radius: 5px;
    margin-bottom: 5px;
}

a.download {
    background: #fff;
    color: #7386D5;
}

a.article,
a.article:hover {
    background: #6d7fcc !important;
    color: #fff !important;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */

#content {
    width: 100%;
    padding: 20px;
    min-height: 100vh;
    transition: all 0.3s;
}

.btn__arrow-toggle{
    border: 0px;
    background-color: transparent;
    transition: all 200ms ease-in-out;

    &.open {
        transform: rotate(180deg);  
    }
    
}
.header__menu-dashboard{
    color: $primary-blue;
}
.menu-dashboard__icons{
    padding: 0 10px;
    height: 30px;
}

.menu-dashboard__user-name{
    font-family: 'Montserrat', sans-serif;
    font-weight: 300;
    font-size: 16px;
    display: block;
}
.tables-dashboard{
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;

    thead th {
        vertical-align: bottom;
        border-bottom: 2px solid $primary-orange;
        background-color: $soft-grey;
        border-top: 0px;
    }
}
.bold-table{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
}
.print-icon-tables{
    width: 30px;
    padding: 0px 6px
}
.page-link {
    position: relative;
    display: block;
    padding: .3rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: $text-grey;
    background-color: #fff;
    border: 1px solid #dee2e6;
}
.dashboard-bk{
    background-color: $soft-grey;
}
.box-containers-dashboard{
    background: #fff;
    -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    border-radius: 4px;
    padding: 30px;
}
// .content100{
//     width: 100%!important;

// }

/*-------datos personales------*/
.dd-pp__lines{
    border-top: 1px solid #80808029;
    display: flex;
    justify-content: space-between;
    padding: 15px 0;
    word-break: break-word;
}
.dd-pp__lines_last-child{
    border-bottom: 1px solid #80808029;
}
.dd-pp__item-title{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700; 
    padding-right: 10px;
    max-width: 140px;
    width: 100%;
}

/*------------tramites-----------*/
.tramites__container{
    background-color: $soft-grey;
    padding: 40px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700; 
    -webkit-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    -moz-box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    box-shadow: 0px 0px 5px -1px rgba(0,0,0,0.15);
    border-radius: 4px;
    display: block;
}
.tramites__container:hover{
    background-color: $text-grey;
    text-decoration: none;
}
.tramites__container:hover h3{
    color: #fff;
}
.label-select-tramite{
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
input[type="radio"] {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .radio label:before {
    content: "";
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    margin-right: 0.5em;
    border-radius: 100%;
    vertical-align: -3px;
    border: 2px solid #000;
    padding: 0.13em;
    background-color: transparent;
    background-clip: content-box;
  }
  .radio label {
    margin-right: 1em;
    display: flex;
  }
  .radio input:hover + label:before {
    border-color: $primary-orange;
  }
  .radio input:checked + label:before {
    background-color: $primary-orange;
    border-color: $primary-orange;
  }
  .radio label:before {
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }

  .medicamentos-links{
      color: $primary-orange;
      text-decoration: underline;
      font-family: 'Montserrat', sans-serif;
      font-weight: 700; 
      display: block;
  }
  .medicamentos-links:hover{
    text-decoration: underline;
    color: $primary-orange;
    opacity: 0.8;
  }

 #upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
 }

 .upload-photo {
    opacity: 0;
    position: absolute;
    z-index: -1;
 }

 .add-file-input{
     text-transform: uppercase;
     text-decoration: underline;
     cursor: pointer;
     font-size: 14px;
 }
 textarea{
     min-height: 112px;
 }
 .link-descarga{
    color: $primary-orange;
    text-decoration: underline;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700; 
    border: none;
    background: none;
 }
 .link-descarga:hover{
    color: $primary-orange;
    text-decoration: underline;
    
 }


 /* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
    #sidebar ul.components {
        margin-top: 100px;
    }

    #sidebar .sidebar-header {
        display: none;
    }

    #sidebar .collapsed-names {
        display: none !important;
    }

    #sidebar.active {
        position: relative !important;
    }

    .tramites__titles-boxes{
        font-size: 16px;
    }
    .tramites__titles__img{
        width: 50%;
    }
    .tramites__container{
        padding: 20px;
    }
    #content{
        width: calc(100% - 60px);
        padding: 0px;
    }
    .content-menu-open{
        width: 100%!important;
    }
    #sidebar {
        min-width: 60px;
        max-width: 60px;
        text-align: center;
        margin-left: 0px !important;
    }
    .dropdown-toggle::after {
        top: auto;
        bottom: 10px;
        right: 50%;
        -webkit-transform: translateX(50%);
        -ms-transform: translateX(50%);
        transform: translateX(50%);
    }
    .wrapper{
        margin-top: 1px;
        position: relative;
    }
    #sidebar.active {
        margin-left: 0 !important;
        position: absolute;
        top: 0px;
        left: 0;
        z-index: 1;
        min-width: 60px;
        max-width: 60px;
        height: 100%;
    }
    #sidebar{
        overflow-y: scroll;
    }
    
    #sidebar .sidebar-header h3,
    #sidebar .CTAs {
        display: none;
    }
    #sidebar .sidebar-header strong {
        display: block;
    }
    #sidebar ul li a {
        padding: 20px 5px;
    }
    .menu-dashboard__icons {
        padding: 0 5px;
        height: 25px;
    }
    #sidebar ul li a span {
        font-size: 0.85em;
    }
    #sidebar ul li a i {
        margin-right: 0;
        display: block;
    }
    #sidebar ul ul a {
        padding: 10px !important;
    }
    #sidebar ul li a i {
        font-size: 1.3em;
    }
    #sidebar {
        margin-left: 0;
    }
    #sidebarCollapse span {
        display: none;
    }
    .btn__arrow-toggle{
        padding: 0px;
    }
    .box-containers-dashboard{
        padding: 15px;
        font-size: 14px;
        margin-top: 100px;
    }
    h4{
        font-size: 16px;
    }
    .dd-pp__lines{
        flex-direction: column;
    }
}