/*---------- PLANES ----------*/
.planes__beneficios{
    margin: 20px 0;
}
.planes__text{
    font-size: 18px;
}
.link-tab-planes{
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    color: $primary-blue;
}
.nav-tabs .link-tab-planes.active{
    color: $primary-orange;
    border-bottom: 5px solid $primary-orange;
}
.nav-tabs .link-tab-planes{
    border-radius: 0;
    border: 0px;
    padding: 30px;
}
.planes__icon-check{
  width: 25px;
  margin-right: 5px;
}

/*---------- CONTACTO ----------*/
.contact__title{
    text-transform: uppercase;
    color: $text-grey;
    margin-bottom: 20px;
}
.contatc__p{
    color: $text-grey;
    margin-bottom: 40px;
}
.form-control__round{
    border-radius: 1.25rem;
    -webkit-appearance: none;
    padding: 15px;
}

select.minimal {
    background-image:
      linear-gradient(45deg, transparent 50%, gray 50%),
      linear-gradient(135deg, gray 50%, transparent 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 20px) calc(1em + 2px),
      calc(100% - 15px) calc(1em + 2px),
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    padding: 0 15px!important;
  }
  
  select.minimal:focus {
    background-image:
      linear-gradient(45deg, green 50%, transparent 50%),
      linear-gradient(135deg, transparent 50%, green 50%),
      linear-gradient(to right, #ccc, #ccc);
    background-position:
      calc(100% - 15px) 1em,
      calc(100% - 20px) 1em,
      calc(100% - 2.5em) 0.5em;
    background-size:
      5px 5px,
      5px 5px,
      1px 1.5em;
    background-repeat: no-repeat;
    border-color: green;
    outline: 0;
  }
  
/*---------- CARTILLA MÉDICA ----------*/
.container__resultado-busqueda{
    border-top: 1px solid #2e37414a;
    padding: 20px;
}
  .resultado-busqueda__title{
      text-transform: uppercase;
      color: $text-grey;
  }  

  .resultado-busqueda__text{
    color: $text-grey;
  }

  /*---------- CENTROS MÉDICOS ----------*/
  .location-map-link{
    color: $primary-orange;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    text-decoration: underline;
  }
  .location-map-link:hover{
    color: $primary-orange;
    text-decoration: underline;
  }
  .location-map-link:focus{
    color: $primary-orange;
    outline: -webkit-focus-ring-color auto 0px;
  }

/*---------- REGLAMENTOS ----------*/
  .regamento__titles{
      color:$primary-orange;
      text-transform: uppercase;
  }
  .orange__titles{
      color:$primary-orange;
  }

  .faqs__title-ppal{
    background-color: $soft-grey;
    padding: 20px;
    border-bottom: 4px solid $primary-orange;
  }
  .faqs__card-header{
      border:0px;
      background-color: transparent;
      color: $text-grey;
      border-bottom: 1px solid #2e37414a;
      padding: 1.75rem 1.25rem;
  }
  .faqs__titles{
    font-family: 'Montserrat', sans-serif!important;
    font-weight: 500!important;
    border: 0px!important;
    background: transparent!important;
    text-align: left!important;
    color: #000 !important;

    &:hover {
      text-decoration: none;
    }

    &:focus {
      text-decoration: none;
    }

    &:active {
      text-decoration: none;
    }
  }
  .faqs-inner-titles{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    padding: 10px 0;
    margin-top: 20px;
    text-transform: uppercase;
  }
.links-inner-faqs{
  text-decoration: underline;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
.links-inner-faqs:hover{
  text-decoration: underline;
  color: $primary-orange;
}
.titles-info-interes{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}
  /*---------- LOGIN ----------*/
.link-forgot-pass{
  color:$primary-blue;
}

.semi-bold-font{
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

/*---------- plan vacunar ----------*/
.bg-grey{
  background-color: $soft-grey;
  padding: 80px 10px;
}
.text-initial{
  text-transform: initial;
}
.att-cliente__items{
  list-style: none;
  padding-left: 0px;
}
.att-socio-icons{
  height: 20px;
  width: 40px;
}
.att-socio__li{
  padding: 10px 0;
  font-size: 18px;
}

/*---------- centros medicos ----------*/
.centros-medicos__icons-info{
  width: 22px;
}
.doble-line-title{
  min-height: 46px;
}
@media (max-width: 452px){
  .att-socio__li{
    font-size: 16px;
  }
  .card-body{
    font-size: 12px;
    padding-left: 0;
    padding-right: 0;
  }
  .links-inner-faqs {
    font-size: 10px;
  }
  select.minimal {
    padding-right: 44px!important;
  }
}
@media (max-width: 768px){
  .faqs__card-header{
    padding: 1.75rem 0.25rem;
  }
  .section_top_responsive{
    margin-top: 40px!important;
  }
}