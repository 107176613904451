
.container__section-banners{
    position: relative;
    max-height: 300px;
    overflow: hidden;
}
.overlay-img-sections{
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00000024;
    width: 100%;
    height: 100%;
}
.titles-float-profile-img{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto 0;
    color: #ffffff;
    display: table;
}
.section-banner_info__line {
    width: 40px;
    color: $sky-blue;
    background-color: $sky-blue;
    margin-left: 0px;
    height: 4px;
    border: none;
}

.section-banner__title {
    text-transform: uppercase;
    font-size: 1em;
}
.section-banner__text{
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
}
.min-banner-ppal{
    min-height: 300px;
}
@media (min-width: 768px){
    .titles-float-profile-img{
        padding: 0 50px;
    }

}
@media (min-width: 1024px){
    .titles-float-profile-img{
        padding: 0 80px;
    }
    .section-banner__title {
        font-size: 4rem;
    }
    .section-banner__text{
        font-size: 20px;
    }
}
@media (min-width: 1440px){
    .titles-float-profile-img{
        padding: 0 100px;
        // margin: auto 0 70px 0;
    }

}


.text-highlight{
    font-size: 18px;
}

@media (max-width: 425px){
    .container__section-banners {
        height: 200px;
    }
    .image__section-banners{
        object-fit: cover;
        top: 0;
        left: 0;
        height: 200px;
    }
}