.whatsapp_float {
    /* Replaced position with flexbox properties */
    display: flex;
    align-items: center;
    justify-content: center;

    /* Set position to fixed */
    position: fixed;

    /* Set bottom and right to negative values */
    bottom: 40px;
    right: 20px;

    /* Reduced font size for better fit */
    width: 48px;
    height: 48px;
    background-color: #25d366;
    color: #fff;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: bold;
    border: none;
    border-radius: 80px;
    box-shadow: 0 0 5px #000;
}



.whatsapp_float_txt {
    display: flex;
    color: #000;
    position: fixed;
    margin-bottom: 20px;
    bottom: 20px;
    right: 80px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.whatsapp_float+.whatsapp_float_txt {
    display: block;
}

