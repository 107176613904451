/*Menu mobile*/
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: #fff;
    overflow-x: hidden;
    transition: 0.5s;
    padding-top: 115px;
    z-index: 99999999;
}
.sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 20px;
    color: $primary-blue;
    display: block;
    transition: 0.3s;
}
.sidenav a:hover {
    color: $primary-blue;
}
.sidenav .closebtn {
    position: absolute;
    top: 0;
    right: 5%;
    font-size: 36px;
    background: none;
    border: none;
    color: #fff;
}

.mobile_toggler.dropdown-toggle.nav-link {
    padding: 8px 8px 8px 32px !important;
}

.header__mobile{
    border-bottom: 1px solid $primary-orange;
    background-color: #fff;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 16;
    margin-top: 38px;
}
.menu-mob_icon-menu{
    width: 70px;
    padding: 10px 20px;
}
.menu-mob_icon-menu__user{
    display: block;
    width: 20px;
    // padding: 10px 20px;
}
.sub-menu-mobile{
    border: 0px;
    margin-left: 20px;
    position: relative;
}
.menu-mob__img{
    position: absolute;
    top: 0;
    border-bottom: 3px solid $primary-orange;
}
.sub-menu-mob__drop{
    border: 0px;
    margin-left: 20px;
}
.btn-user{
    background-color: transparent;
    border: none;
}
.btn-user a:hover{
    text-decoration: none;
    border-bottom: 0px!important;
}
  
@media screen and (max-height: 450px) {
    .sidenav {padding-top: 15px;}
    .sidenav a {font-size: 18px;}
}

/*Menu desktop*/
.header__orange{
    background-color: $primary-orange;
    color: #fff;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 12px;
    position: fixed;
    top: 0;
    z-index: 1130;
    width: 100%;
    text-align: center;
    padding: 8px 0;
    border-bottom: #52ece5 5px solid;
}
.navbar__desktop{
    flex-direction: row;
}

ul.navbar__desktop > li.nav-item > a {
    display: inline-block;
    border-bottom: 3px solid #fff;

    &:hover {
        text-decoration: none;
        border-bottom: 3px solid $primary-orange;
    }
}

ul.navbar__desktop {

    &.no-hover-line {
        li {
            &.nav-item {
                a {
                    padding-left: 15px;
                    padding-right: 15px;
                    border-bottom: none;

                    &:hover {
                        border-bottom: none !important;
                    }
                }
            }
        }
    }
}

.btn-menu {
    display: block;
    width: 100%;
    color: #1600dc;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 14px;
    border: none;
    background: none;
    padding: 15px;
    text-align: left;
    white-space: normal;

    &:hover {
        background-color: #F5F6F8;
        border-bottom: none !important;
    }
}



.dropdown-toggle.nav-link {
    color: $primary-blue !important;
}

.dropdown.nav-item ul.dropdown-menu__as > li > a {
    white-space: normal
}


.bg-nav-as{
    background-color: #fff!important;
    border-bottom: 1px solid $primary-orange;
    top: 40px;

    li{
        padding: 0 10px;
    }

    a{
        color: $primary-blue;
        text-transform: uppercase;
        font-family: 'Montserrat', sans-serif;
        font-weight: 700;
        font-size: 14px;
        padding: 5px 0;
    }
}
.navbar__desktop .dropdown-menu__as{
    position: absolute;
    top: 53px;
    border-radius: 0px;
    border: 0px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.09);

    li{
        padding: 0px;
    }

    a{
        padding: 15px 20px;
        display: block;
    }
    a:hover{
        text-decoration: none;
        border: 0px;
        background-color: $soft-grey;
    }
}

.dropdown-menu__as_user{
    left: auto;
    right: 0;
}
.dropdown-menu__as{
    position: absolute;
    top: 53px;
    border-radius: 0px;
    border: 0px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.09);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.09);
    box-shadow: 0px 0px 5px 1px rgba(0,0,0,0.09);
    width: 180px;

    li{
        padding: 0px;
    }

    a{
        padding: 10px 20px;
        display: block;
    }
    a:hover{
        text-decoration: none;
        border: 0px;
        background-color: $soft-grey;
    }
}
.header__logo-as{
    width: 100px;
}
.header__logo-as__mobile{
    width: 80px;
    padding: 10px;
}
.mySidenav__config{
    width: 300px!important;
}
.bg-nav-as a.navbar-brand:hover{
    border: 0;
}
@media screen and (min-width: 1024px){
    .header__orange{
        font-size: 14px;
    }
    .header__logo-as{
        width: 80px;
    }
}

button.nav-link__as-mob {
    padding: 8px 8px 8px 32px !important;
    text-decoration: none;
    font-size: 20px;
    color: $primary-blue;
    display: block;
    transition: 0.3s;
    width: 100%;
    text-align: left;
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.nav-link__as-mob{
    padding: 8px 8px 8px 32px!important;
}
.nav-link__as-mob_item{
    padding: 8px 8px 8px 40px!important;
}
.nav-link__as-mob[data-toggle].collapsed:after {
    content: "▾";
    margin-left: 5px;
}
.nav-link__as-mob[data-toggle]:not(.collapsed):after {
    content: "▴";
    margin-left: 5px;
}
.user-mobile-name{
    padding: 10px 20px;
    border-bottom: 1px solid #55555529;
}
@media screen and (max-width: 768px){
    .dropdown-toggle__as-mob{
        padding: 10px 20px;
    }
    .dropdown-toggle__as-mob::after{
    -webkit-transform: none!important;
    -ms-transform: none!important;
    transform: none!important;
    }
}