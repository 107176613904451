.footer__container{
    background-color: $primary-blue;
    color: #fff;
    padding: 60px 30px;
}

.footer__logo-line{
    border-right: 1px solid;
}

footer ul{
    list-style: none;
    padding: 0px;
}
.footer-as__links_desktop{
    color: #fff;
    display: block;
    padding: 5px 3px;
}

footer ul li a:hover{
    color: #fff;
}
.card__footer{
    border: 0px;
    background-color: transparent;
}
.footer-mov__title{
    cursor: pointer;
    margin: 0px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ffffff54;
}
.footer-mov__title_last{
    border:0;
}

.footer__titles-desktop{
    font-size: 1rem;
}


@media (min-width: 1024px) and (max-width:1300px){
    .footer__container{
        font-size: 12px;
    }
    .footer__titles-desktop{
        font-size: 14px;
    }
}
@media (max-width: 768px) {
    .footer__container{
        padding:0;
    } 
    .footer-as__links_desktop{
        font-size: 16px;
    }
}


  .panel-title>a, .panel-title>a:active{
    display:block;
    padding:15px;
    color:#fff;
    font-size:16px;
    font-weight:bold;
    text-transform:uppercase;
    letter-spacing:1px;
    word-spacing:3px;
    text-decoration:none;
  }
  .panel-heading  a:before {
     font-family: 'Glyphicons Halflings';
     content: "\e114";
     float: right;
     transition: all 0.5s;
  }
  .panel-heading.active a:before {
      -webkit-transform: rotate(180deg);
      -moz-transform: rotate(180deg);
      transform: rotate(180deg);
  } 

.card__footer {
    .card-header {
        border-bottom: none;
    }

    .footer-mov__title {
        border-radius: 0;
        color: #fff;
    }
}
