.home-slider__info-container{
    background-color: $primary-blue;
    padding: 20px 30px;
    color: #fff;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.home-slider_info{
    text-transform: uppercase;
}
.home-slider_title{
    text-transform: uppercase;
    margin: 10px 0;
}
.home-slider_info__arrow{
    padding-left: 5px;
}
.home-slider_info__line{
    width: 40px; 
    color: #fff;
    background-color: $sky-blue;
    margin-left: 0px;
    height:3px;
    border:none;
}

.container-boxes_light{
    padding: 50px;
    background-color: #f5f6f8;
    height: 100%;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.09);    
}
.container-boxes_dark{
    color: #fff;
    padding: 50px;
    background-color: $primary-blue;
    height: 100%;
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.09);
}

.bg-soft-grey{
    background-color: $soft-grey;
    padding: 80px 20px;
}
.grey-titles{
    color: $dark-grey;
}
.home__subtiles{
    font-family: 'Montserrat', sans-serif;
    text-transform: uppercase;
    color: $dark-grey;
    font-size: 20px;
}
.home__circular--landscape{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.home__images-round{
    width: 100%;
}
.centros__titulos{
    font-size: 18px;
}
@media (max-width: 768px){
    .grey-titles{
        font-size: 20px;
    }
    .centros__titulos{
        font-size: 16px;
    }
    .home-slider_title{
        font-size: 35px;
    }
    .container-boxes_light{
        padding: 30px;
        
    }
    .container-boxes_dark{
        padding: 30px;
    }
}
@media (min-width: 1024px){
    .home-slider__info-container{
        padding: 50px;
    }
}
@media (min-width: 1440px){
    .home-slider_title{
        font-size: 3rem;
    }
    .home-slider__info-container{
        padding: 70px;
    }
}